import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiParam } from '@core/api-services/role-api/dto/role.dto';
import { ISelectValue } from '@shared/components/form-control-generator/form-control-generator.component';

@Injectable({
  providedIn: 'root',
})
export class CourseManagementService {
  constructor(private http: HttpClient) {}
  BE_API_BASE_URL = environment.BE_API_BASE_URL;

  private _teacherAndSubjectList = signal<ISelectValue[]>([]);
  teacherAndSubjectList = this._teacherAndSubjectList.asReadonly();

  addCourse(payload: any): Observable<any> {
    return this.http.post(`${this.BE_API_BASE_URL}/courses`, payload);
  }

  editCourse(id: number, payload: any): Observable<any> {
    return this.http.put(`${this.BE_API_BASE_URL}/courses/${id}`, payload);
  }

  getAllSubjects(): Observable<any> {
    return this.http.get(`${this.BE_API_BASE_URL}/subjects`);
  }

  getCourses(params: Partial<ApiParam>) {
    return this.http.get<any>(`${this.BE_API_BASE_URL}/courses`, {
      params: {
        ...params,
      },
    });
  }

  getCourseById(id?: number) {
    return this.http.get<any>(`${this.BE_API_BASE_URL}/courses/${id}`);
  }

  deleteCourse(id: number, hasContents: boolean) {
    return this.http.delete<any>(
      hasContents
        ? `${this.BE_API_BASE_URL}/courses/${id}/contents`
        : `${this.BE_API_BASE_URL}/courses/${id}`,
    );
  }

  getSubjectAndTeacherData(): Observable<any> {
    return this.http.get(`${this.BE_API_BASE_URL}/courses/table-filters-data`);
  }

  loadSubjectAndTeacherData(): Promise<void> {
    return new Promise((res, rej) => {
      this.getSubjectAndTeacherData().subscribe({
        next: (data) => {
          const transformedData =
            data?.data?.map((item: any) => ({
              value: item.id,
              displayedValue: item.name,
            })) || [];
          this._teacherAndSubjectList.set(transformedData);
          res();
        },
        error: (error) => rej(error),
      });
    });
  }
}
